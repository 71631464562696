import { useCallback } from 'react';
import { useStaticContent } from '~/libs/queries/bff';
import Router from 'next/router';

function getValue(obj: { [key: string]: any }, path: string): any {
    try {
        if (!path) {
            return obj;
        }

        const properties = path.split('.');
        return getValue(obj[properties.shift() as string], properties.join('.'));
    } catch (error) {
        return undefined;
    }
}

const useTranslations = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const { translations } = useStaticContent();

    return useCallback(
        (selector: string, defaultValue: string) => {
            
            // We can't access the Router instance on the server,
            // therefor we assert that window is defined, to be sure that we are on the client.
            if (typeof window !== 'undefined' &&
                Router.query?.debugTranslations === 'true') 
            {
                return selector;
            }

            if (!selector || !translations) {
                return `##${defaultValue}`;
            }

            return getValue(translations, selector) || `##${defaultValue}`;
        },
        [translations]
    );
};

export default useTranslations;
