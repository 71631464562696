import { useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { Session } from 'next-auth';
import { useRouter } from 'next/router';
import { Products } from '~/models/products';
import { Bff } from '~/models/bff';
import useUser from '~/libs/use-user';
import { queryKeys } from '~/libs/queries/query-keys';
import { apiClient } from '~/services/api-client';
import { PRODUCTS_GET_OE_SEARCH } from '../constants';
import { createUrl, LocaleOptions } from '~/services/service-endpoint';
import { useCarInfo } from '~/page-elements/car-info-banner/use-car-info-banner.hook';

export type UseOESearchQueryError = {
    status: number;
};

export type UseOESearchQueryResponse = Products.IProduct[];

export type UseOESearchQueryOptions = UseQueryOptions<UseOESearchQueryResponse, UseOESearchQueryError>;

export type UseOESearchProps = {
    oeReference?: string;
    manufacturerId?: number;
    options?: UseOESearchQueryOptions;
};

export function useOESearch({ oeReference, manufacturerId, options }: UseOESearchProps) {
    const { locale } = useRouter();
    const { user, isLoggedIn } = useUser();

    return useQuery<UseOESearchQueryResponse, UseOESearchQueryError>({
        queryKey: queryKeys.products.oeSearch(oeReference, manufacturerId),
        queryFn: () =>
            getOESearch({
                oeReference,
                manufacturerId,
                user,
                locale,
            }),
        enabled:
            isLoggedIn &&
            !!oeReference &&
            !!manufacturerId &&
            // Enabled by default
            (options?.enabled ?? true),
        ...options,
    });
}

export function usePrefetchOESearch() {
    const queryClient = useQueryClient();

    const { locale } = useRouter();
    const { user } = useUser();
    const { data: vehicle } = useCarInfo();

    const prefetch = async (oeReference: string, manufacturerId: number) => {
        const getProductQueryKey = queryKeys.products.oeSearch(oeReference, manufacturerId);

        await queryClient.cancelQueries({
            queryKey: getProductQueryKey,
        });

        await queryClient.prefetchQuery<UseOESearchQueryResponse>({
            queryKey: getProductQueryKey,
            queryFn: () =>
                getOESearch({
                    oeReference,
                    manufacturerId,
                    user,
                    locale,
                    vehicle,
                }),
        });
    };

    return { prefetch };
}

export type GetProductProps = {
    oeReference?: string;
    manufacturerId?: number;
    locale?: string;
    user?: Session | null;

    vehicle?: Bff.ICarInfo;
};

export function getOESearch({ oeReference, manufacturerId, locale, user, vehicle: { licensePlate, vin, carId } = {} }: GetProductProps) {
    return apiClient.auth(user).get(
        createUrl(
            {
                endpoint: PRODUCTS_GET_OE_SEARCH,
                localeOption: LocaleOptions.path,
                query: {
                    carModelTypeId: carId,
                    oeNumber: oeReference,
                    licensePlate: licensePlate?.number,
                    licensePlateCountry: licensePlate?.country,
                    manufacturerId,
                    vin,
                },
            },
            locale,
        ),
    );
}
