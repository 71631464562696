import { useRouter } from 'next/router';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { cache, mutate } from 'swr';
import { useStaticContent } from '~/libs/queries/bff';
import putImpersonate from './put-impersonate.service';
import deleteImpersonate from './delete-impersonate.service';
import useUser from '~/libs/use-user';
import logImpersonation from './log-impersonation.service';
import changeBasketOwnership from '../basket/change-basket-ownership.service';
import { addErrorToast, LayoutActionTypes, useLayoutDispatch, useLayoutState } from '~/context/layout.context';
import { useManualWebOrdersCount } from '~/libs/queries/users/hooks/use-manual-web-orders-count';
import { useLatestCustomers } from '~/libs/queries/users/hooks/use-latest-customers';
import { useLatestOrders } from '~/libs/queries/basket/hooks/use-latest-orders';

const refreshGlobalFetchCacheData = async (queryClient: QueryClient) => {
    // Will refresh all endpoints that are in the cache
    cache.keys().forEach((key) => mutate(key));
    queryClient.invalidateQueries();
};

const useImpersonateMode = () => {
    const queryClient = useQueryClient();
    const { push } = useRouter();
    const dispatch = useLayoutDispatch();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const { loggedInPageUrl } = useStaticContent();
    const { profile, user } = useUser();
    const { selectedDepartment, isCheckout } = useLayoutState();
    const { refetch: mutateLatestCustomers } = useLatestCustomers();
    const { refetch: mutateLatestOrders } = useLatestOrders();
    const { refetch } = useManualWebOrdersCount();

    const refreshInternalDashboard = async () => {
        await mutateLatestCustomers();
        await mutateLatestOrders();
        await refetch();
    };

    /**
     * Changes the ownership of a basket.
     */
    const changeOwnership = async (customerId: string, basketId: string) => {
        if (!basketId) return;

        dispatch({
            type: LayoutActionTypes.SetUpdatingBasketContent,
            payload: true,
        });

        try {
            await changeBasketOwnership(basketId, customerId, selectedDepartment?.id as number, !isCheckout, user);
        } catch (ex: any) {
            dispatch(addErrorToast(ex?.errorMessage || 'Unknown error'));
        } finally {
            dispatch({
                type: LayoutActionTypes.SetUpdatingBasketContent,
                payload: false,
            });
        }
    };

    const activate = async (customerId: string, basketIdForTransfer?: string) => {
        if (!customerId) return;
        dispatch({
            type: LayoutActionTypes.SetLoading,
            payload: true,
        });
        try {
            await putImpersonate(customerId, user);
            basketIdForTransfer && (await changeOwnership(customerId, basketIdForTransfer));
            refreshGlobalFetchCacheData(queryClient);
            await logImpersonation(user);
            dispatch({
                type: LayoutActionTypes.SetSelectedDepartment,
                payload: null,
            });
            dispatch({
                type: LayoutActionTypes.SetImpersonated,
                payload: true,
            });
            await refreshInternalDashboard();
            push(loggedInPageUrl || '/');
        } catch (error: any) {
            dispatch(addErrorToast(error.errorMessage));
        } finally {
            dispatch({
                type: LayoutActionTypes.SetLoading,
                payload: false,
            });
        }
    };

    // changeBasketOwner is basket id if should change ownership
    const deactivate = async (basketIdForTransfer?: string) => {
        dispatch({
            type: LayoutActionTypes.SetLoading,
            payload: true,
        });
        try {
            await deleteImpersonate(user);
            basketIdForTransfer && (await changeOwnership(profile?.sub as string, basketIdForTransfer));
            dispatch({
                type: LayoutActionTypes.SetSelectedDepartment,
                payload: null,
            });
            dispatch({
                type: LayoutActionTypes.SetImpersonated,
                payload: false,
            });
            await refreshGlobalFetchCacheData(queryClient);
            await refreshInternalDashboard();
            push(loggedInPageUrl || '/');
        } catch (error: any) {
            dispatch(addErrorToast(error.errorMessage));
        } finally {
            refreshInternalDashboard();
            dispatch({
                type: LayoutActionTypes.SetLoading,
                payload: false,
            });
        }
    };

    return {
        activate,
        deactivate,
    };
};

export default useImpersonateMode;
