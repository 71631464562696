import { createQueryKeys } from '@lukemorales/query-key-factory';

export const vehicleKeys = createQueryKeys('vehicle', {
    oilInfo: (profileId?: string, ftzCodeList?: string[]) => [{ profileId, ftzCodeList }],
    productsVehicleInfoByIds: (locale?: string, includeExpertProducts?: boolean) => [{ locale, includeExpertProducts }],
    vehiclesFits: (locale?: string, variantIDSs?: string[], includeExpertProducts?: boolean) => [{ locale, variantIDSs, includeExpertProducts }],
    quickSearchVehicle: (locale?: string, licensePlateCountry?: string, query?: string) => [locale, licensePlateCountry, query],
    vehicleInvoices: (locale?: string, licensePlateOrVin?: string) => [{ locale, licensePlateOrVin }],
    vehicleInvoicesCount: (locale?: string, licensePlateOrVin?: string, departmentId?: number) => [{ locale, licensePlateOrVin, departmentId }],
    vehicleDetails: (locale?: string, licensePlateCountry?: string | null, licensePLateNumber?: string, vin?: string, carId?: string | number) => [
        { locale, licensePlateCountry, licensePLateNumber, vin, carId },
    ],
    latestVehicles: (limit?: number) => [{ limit }],
    vehicleModelTypes: (locale?: string, includeExpertVehicles?: boolean | null, modelId?: number | string) => [
        { locale, includeExpertVehicles, modelId },
    ],
    vehicleModels: (locale?: string, manufacturerId?: number, includeExpertVehicles?: boolean) => [locale, manufacturerId, includeExpertVehicles],
    pictogram: (
        locale?: string,
        activeProfileId?: string,
        carModelTypeId?: string,
        licensePlate?: string,
        licensePlateCountry?: string,
        vin?: string,
        includeExpertProducts?: boolean,
    ) => [{ locale, activeProfileId, carModelTypeId, licensePlate, licensePlateCountry, vin, includeExpertProducts }],
    sparePartCategoryByVehicle: (locale?: string, activeProfileId?: string, url?: string) => [{ locale, activeProfileId, url }],
    damCalculator: (locale?: string) => [locale],
    variantVehicleInfo: (locale?: string, ftzCodes?: string[], carId?: string, includeExpertProducts?: boolean | null) => [
        {
            locale,
            ftzCodes,
            carId,
            includeExpertProducts,
        },
    ],
});
