import { CmsData } from '~/models/cms-data.d';

export function flattenGrid(grid: CmsData.IGrid): any[] {
    // this could be edited when we have more clear overview of the data structure
    const pageElements = grid?.sections
        .flatMap((section) => section.rows)
        .flatMap((row) => row.areas)
        .flatMap((area) => area.controls);

    return pageElements ?? [];
}
