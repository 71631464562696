import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { LayoutActionTypes, useLayoutDispatch, useLayoutState } from '~/context/layout.context';
import { useStaticContent } from '~/libs/queries/bff';
import { flattenGrid } from '~/helpers/cms.helper';
import { ICmsPageStatusCode } from '~/models/dev';
import { Umbraco } from '~/models/umbraco.d';
import { Vehicles as VehicleTypes } from '~/models/vehicles';
import { usePage } from '~/libs/queries/bff';
import type { Bff } from '~/models/bff';

export const findVehicleWidget = (pageContent: ICmsPageStatusCode) => {
    const carInfoBanner = flattenGrid(pageContent?.grid).filter(({ value }) => value.dtgeContentTypeAlias === Umbraco.WidgetTypes.carBannerWidget);
    return carInfoBanner[0]?.value?.value;
};

export type CarInfoBannerVehicle = {
    carId?: string;
    licensePlate?: VehicleTypes.ILicensePlate;
    vin?: string;
    vehicleClass?: `${Bff.VehicleClass}` | Bff.VehicleClass;
};

export interface ICarInfoBannerData {
    vehicle: CarInfoBannerVehicle;
    arrowLink?: string;
    carLink: string;
}

export function useCarInfo() {
    const { query } = useRouter();

    return usePage<CarInfoBannerVehicle | undefined>((data) => {
        const widgetData = findVehicleWidget(data);

        let vehicleData: CarInfoBannerVehicle | undefined;

        if (widgetData) {
            const dataSource = widgetData.hasOwnProperty('carId') ? widgetData : widgetData.cmsData || {};
            vehicleData = {
                carId: dataSource.carId,
                licensePlate: dataSource.licensePlate,
                vin: dataSource.vin,
                vehicleClass: dataSource.vehicleClass,
            };
        } else if (query?.licensePlate || query?.carModelTypeId || query?.vin) {
            vehicleData = {
                carId: typeof query?.carModelTypeId === 'string' ? query.carModelTypeId : undefined,
                vin: typeof query?.vin === 'string' ? query.vin : undefined,
                licensePlate: {
                    number: typeof query?.licensePlate === 'string' ? query.licensePlate : undefined,
                    country: typeof query?.licensePlateCountry === 'string' ? query.licensePlateCountry : undefined,
                },
                // TODO: Make Bff.VehicleClass to an enum of string instead of auto assigned integers
                vehicleClass: 0, // Vehicle class unknown
            };
        }

        return vehicleData;
    });
}

export type TopmotiveMultiMatchInfo = {
    url: string;
    title: string;
};

export function useTopmotiveMultiMatchInfo() {
    return usePage<TopmotiveMultiMatchInfo>((data) => {
        return flattenGrid(data?.grid).find((item) => item.value.dtgeContentTypeAlias === Umbraco.WidgetTypes.sparePartCategoryListWidget)?.value
            ?.value?.data?.matchingCar;
    });
}

const useCarInfoBanner = (pageContent: ICmsPageStatusCode) => {
    const dispatch = useLayoutDispatch();
    const { licensePlateCountry } = useLayoutState();
    const { asPath, query } = useRouter();
    const widgetData = useMemo(() => findVehicleWidget(pageContent), [pageContent]);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const { licensePlateLanguages } = useStaticContent();

    const [carInfoData, setCarInfoData] = useState<ICarInfoBannerData | null>(null);

    const { data: vehicle } = useCarInfo();

    useEffect(() => {
        // Store vehicle in layout state
        dispatch({
            type: LayoutActionTypes.SetVehicle,
            payload: vehicle ?? null,
        });

        if (vehicle?.licensePlate?.country !== licensePlateCountry) {
            //!TODO Fix KAT-6643 type lp:any once  useStaticContent() returns the correct type
            const countryCode = vehicle?.licensePlate?.country || licensePlateLanguages?.find((lp: any) => lp.isDefault)?.code || null;

            dispatch({
                type: LayoutActionTypes.SetLicensePlateCountry,
                payload: countryCode,
            });
        }

        setCarInfoData(
            vehicle
                ? {
                      vehicle,
                      arrowLink: widgetData?.data?.arrowLink,
                      carLink: widgetData?.data?.carLink,
                  }
                : null,
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageContent, asPath, licensePlateLanguages, query, vehicle]);

    return {
        carInfoBannerData: carInfoData,
    };
};

export default useCarInfoBanner;
