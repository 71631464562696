import { useMutation } from '@tanstack/react-query';
import { Products } from '~/models/products';
import useUser from '~/libs/use-user';
import { apiClient } from '~/services/api-client';
import { createUrl, endpoints, LocaleOptions } from '~/services/service-endpoint';
import { useRouter } from 'next/router';

export type UseRequestOeProductParams = {
    subject?: string;
    customerNumber?: string;
    companyName?: string;
    contact?: string;
    address?: string;
    department?: string;
    phoneNumber?: string;
    email?: string;
    regNr?: string;
    modelName?: string;
    modelTypeId?: string;
    engineCode?: string;
    firstRegistration?: string;
    lastInspection?: string;
    manufacturerName?: string;
    vin?: string;
    modelTypeName?: string;
    errorCodes?: string;
    oeType?: `${Products.OeType}`;
    description?: string;
    files?: string;
};

export function useRequestOeProduct() {
    const { locale } = useRouter();
    const { user } = useUser();

    return useMutation<unknown, Products.ISubmitOeSparePartsResult | Record<string, never>, UseRequestOeProductParams>({
        mutationFn: (variables) => {
            const formData = new FormData();

            for (const [key, val] of Object.entries(variables)) {
                formData.append(key.charAt(0).toUpperCase() + key.slice(1), val);
            }

            return apiClient.auth(user).post(
                createUrl(
                    {
                        endpoint: endpoints.products.oeRequestPost,
                        localeOption: LocaleOptions.path,
                    },
                    locale,
                ),
                {
                    body: formData,
                },
            );
        },
    });
}
