import type { QueryFunctionContext } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { minutes, seconds } from '~/helpers/time.helper';
import useUser from '~/libs/use-user';
import { apiClient } from '~/services/api-client';
import { IErrorPage, IPage } from '~/services/api-client/lib/api-client';
import { QueryKeys, queryKeys } from '../../query-keys';
import { IPrefetch } from '../../types';
import { getContentUrl } from '../utils/get-content-url';
import { Session } from 'next-auth';

// User-specific data
export function usePage<SelectType = IPage>(select?: (data: IPage) => SelectType) {
    const { user } = useUser();
    const { locale, asPath } = useRouter();

    // const path = decodeURIComponent(asPath.split(/[?#]/)[0]) // Remove any hash or query params to prevent query key to change
    const path = decodeURIComponent(asPath);

    return useQuery<IPage, IErrorPage, SelectType, QueryKeys['bff']['page']>(
        queryKeys.bff.page(locale, path, user),
        (args) => fetchPage(args, { user }),
        {
            select,
            enabled: user?.isLoggedIn,
            staleTime: minutes(5),
            cacheTime: minutes(10),
            retryDelay: seconds(10),
            refetchOnWindowFocus: true,
            keepPreviousData: true,
            retry: (failureCount, error) => {
                // Don't retry if user is does not have access to the requested page
                if (error.statusCode === 401) {
                    return false;
                }

                // Any other failed request will be retried.
                return failureCount < 2;
            },
        },
    );
}

// Non user-specific data
export function prefetchPage({ locale, queryClient, asPath }: IPrefetchProps) {
    const path = decodeURIComponent(asPath);

    return queryClient.fetchQuery(queryKeys.bff.page(locale, path), (context) =>
        fetchPage(context, { returnEmptyOnAuthError: true, returnEmptyOnNotFoundError: true }),
    );
}

type fetchPageOptions = {
    user?: Session | null;
    returnEmptyOnAuthError?: boolean;
    returnEmptyOnNotFoundError?: boolean;
};

function fetchPage(context: QueryFunctionContext<QueryKeys['bff']['page']>, options?: fetchPageOptions) {
    const { signal, queryKey } = context;
    const [, , { locale, path }] = queryKey;
    const url = getContentUrl(`/cms/${locale}${path?.replace(/\/$/, '')}`); // Remove trailing slash

    if (options?.user) {
        return apiClient.auth(options.user).get(url, { signal });
    }

    return apiClient.get(url, {
        signal,
        returnEmptyOnAuthError: options?.returnEmptyOnAuthError,
        returnEmptyOnNotFoundError: options?.returnEmptyOnNotFoundError,
    });
}

interface IPrefetchProps extends IPrefetch {
    asPath: string;
    locale: string | undefined;
}
